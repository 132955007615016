html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    min-height: 100vh !important;
    font-family: "Open Sans", sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

/**************************/
/* CSS Animations Classes */
/**************************/

/* 
The following class-endings: *-enter, *-enter-active, *-exit, *-exit-active,
have very specific meanings to <CSSTransition />
(see more: https://reactcommunity.org/react-transition-group/css-transition)
*/

.fade-enter {
    opacity: 0;
    transform: translate(0, 25px);
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transform: translate(0, 0);

    transition: opacity 250ms ease-out, transform 300ms ease;
}

.fade-exit {
    opacity: 1;
    transform: translate(0, 0);
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transform: translate(0, 30px);

    transition: opacity 250ms ease-out, transform 300ms ease;
}

/**************************/
/* Generic Styles Classes */
/**************************/

body {
    font-family: sans-serif;
    /* background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
        linear-gradient(-45deg, #808080 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #808080 75%),
        linear-gradient(-45deg, transparent 75%, #808080 75%); */
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

main {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

main * {
    background: white;
    color: blue;
    padding: 50px;

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

main *:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

main *:active {
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}

.image-gradient {
    background: rgb(2, 0, 36);
    background: linear-gradient(180deg,
            rgba(2, 0, 36, 0) 0%,
            rgba(0, 0, 0, 0.7) 85%,
            rgba(0, 0, 0, 1) 100%);
}

.layered-box-light {
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.015),
        0 2px 2px hsl(0deg 0% 0% / 0.03), 0 4px 4px hsl(0deg 0% 0% / 0.015),
        0 8px 8px hsl(0deg 0% 0% / 0.03), 0 16px 16px hsl(0deg 0% 0% / 0.015);
}

.layered-box {
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.03),
        0 2px 2px hsl(0deg 0% 0% / 0.03), 0 4px 4px hsl(0deg 0% 0% / 0.03),
        0 8px 8px hsl(0deg 0% 0% / 0.03), 0 16px 16px hsl(0deg 0% 0% / 0.03);
}

.layered-box-strong {
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.07),
        0 2px 2px hsl(0deg 0% 0% / 0.07), 0 4px 4px hsl(0deg 0% 0% / 0.07),
        0 8px 8px hsl(0deg 0% 0% / 0.07), 0 16px 16px hsl(0deg 0% 0% / 0.07);
}

.float-bar {
    position: fixed;
    background-color: #f7f8f9;
    width: 100%;
    top: 60px;
    z-index: 1000;
    transition: 0.5s;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "Open Sans", sans-serif;
}

.legal {
    text-align: left;
}

.legal h1,
.legal h2,
.legal h3,
.legal h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: bold !important;
}

.legal h2 {
    margin: auto !important;
    padding-bottom: 20px;
    font-size: 20px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.push-frame {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    min-width: 300px;
    max-width: 600px;
    width: -webkit-fill-available;
    height: 200px;
    z-index: 10000;
    background-color: transparent;
}

*:focus {
    box-shadow: none !important;
    outline: 0 !important;
    border: 0px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    opacity: 1;
    filter: drop-shadow(0px 0px 5px black);
}

ul {
    transform: translateX(15px);
}